import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {pristineShortcutsComponent} from './shortcuts.component';

// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    pristineShortcutsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatTooltipModule
  ],
  exports: [
    pristineShortcutsComponent
  ],
  providers: [
    // CookieService
  ]
})
export class pristineShortcutsModule {
}
