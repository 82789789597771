<div #shortcuts id="pristine-shortcuts">

  <div *ngIf="!mobileShortcutsPanelActive" class="shortcuts-mobile-toggle" fxHide fxLayout="row"
       fxLayoutAlign="start center" fxShow.lt-md>
    <button (click)="showMobileShortcutsPanel()" mat-icon-button>
      <mat-icon class="amber-600-fg">star</mat-icon>
    </button>
  </div>

  <div class="shortcuts" fxHide fxLayout="row" fxShow.gt-sm>

    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout="row" fxLayoutAlign="start center">

        <div *ngFor="let shortcutItem of shortcutItems" class="w-40 h-40 p-4" fxLayout="row"
             fxLayoutAlign="center center">

          <a [routerLink]="shortcutItem.url" mat-icon-button matTooltip="{{shortcutItem.title}}">
            <mat-icon *ngIf="shortcutItem.icon" class="secondary-text">{{shortcutItem.icon}}</mat-icon>
            <span *ngIf="!shortcutItem.icon" class="h2 secondary-text text-bold">
                            {{shortcutItem.title.substr(0, 1).toUpperCase()}}
                        </span>
          </a>

        </div>

        <button (menuOpened)="onMenuOpen()" [matMenuTriggerFor]="addMenu" mat-icon-button
                matTooltip="Click to add/remove shortcut">
          <mat-icon class="amber-600-fg">star</mat-icon>
        </button>

      </div>

      <div class="shortcuts-mobile-close" fxHide fxLayout="row" fxLayoutAlign="start center" fxShow.lt-md>
        <button (click)="hideMobileShortcutsPanel()" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </div>

    <mat-menu #addMenu="matMenu" class="w-240">

      <mat-form-field (click)="$event.stopPropagation()" class="px-16 w-100-p" floatLabel="never">
        <input #searchInput (input)="search($event)" matInput placeholder="Search for an app or a page">
      </mat-form-field>

      <mat-divider></mat-divider>

      <mat-nav-list *ngIf="!searching" pristinePerfectScrollbar style="max-height: 312px; overflow: auto">

        <mat-list-item (click)="toggleShortcut($event, shortcutItem)"
                       *ngFor="let shortcutItem of shortcutItems">

          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

            <mat-icon *ngIf="shortcutItem.icon" class="mr-8 secondary-text" mat-list-icon>
              {{shortcutItem.icon}}
            </mat-icon>

            <span *ngIf="!shortcutItem.icon" class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold"
                  fxLayout="row" fxLayoutAlign="center center">
                            {{shortcutItem.title.substr(0, 1).toUpperCase()}}
                        </span>

            <p fxFlex matLine>{{shortcutItem.title}}</p>

            <mat-icon class="ml-8 amber-fg">star</mat-icon>

          </div>

        </mat-list-item>

        <mat-list-item *ngIf="shortcutItems.length === 0">
          <p>
            <small>No shortcuts yet!</small>
          </p>
        </mat-list-item>

      </mat-nav-list>

      <mat-nav-list *ngIf="searching" pristinePerfectScrollbar style="max-height: 312px; overflow: auto">

        <mat-list-item (click)="toggleShortcut($event, navigationItem)"
                       *ngFor="let navigationItem of filteredNavigationItems">

          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

            <mat-icon *ngIf="navigationItem.icon" class="mr-8 secondary-text" mat-list-icon>
              {{navigationItem.icon}}
            </mat-icon>

            <span *ngIf="!navigationItem.icon" class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold"
                  fxLayout="row" fxLayoutAlign="center center">
                            {{navigationItem.title.substr(0, 1).toUpperCase()}}
                        </span>

            <p fxFlex matLine>{{navigationItem.title}}</p>

            <mat-icon *ngIf="isInShortcuts(navigationItem)" class="ml-8 amber-fg">star</mat-icon>

          </div>

        </mat-list-item>

      </mat-nav-list>

    </mat-menu>

  </div>

</div>
