import {Component} from '@angular/core';

@Component({
  selector: 'pristine-demo-content',
  templateUrl: './demo-content.component.html',
  styleUrls: ['./demo-content.component.scss']
})
export class pristineDemoContentComponent {
  /**
   * Constructor
   */
  constructor() {
  }
}
