<ng-container *ngIf="!item.hidden">

  <div [ngClass]="item.classes" class="group-title">
    <span [translate]="item.translate" class="hint-text">{{ item.title }}</span>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <pristine-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></pristine-nav-vertical-group>
      <pristine-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                         [item]="item"></pristine-nav-vertical-collapsable>
      <pristine-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></pristine-nav-vertical-item>
    </ng-container>
  </div>

</ng-container>
