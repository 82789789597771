<!-- PROGRESS BAR -->
<pristine-progress-bar></pristine-progress-bar>
<!-- / PROGRESS BAR -->

<!--Loading-->
<ngx-spinner
  [fullScreen]="true"
  bdColor="rgba(0,0,0,0.64)"
  bdOpacity=0.9
  color="#03a9f4"
  size="large"
  type="{{ loading_style[styleindex] }}"
>
  <p></p>
  <p style="color: white; font-size:20px; margin-top: 100px">  {{ funny_loading_text[textindex] }} </p>
</ngx-spinner>
<!--/ Loading -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="pristineConfig.layout.style === 'vertical-layout-1'">
  <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<pristine-sidebar [invisibleOverlay]="true" class="theme-options-sidebar" name="themeOptionsPanel" position="right">
  <pristine-theme-options></pristine-theme-options>
</pristine-sidebar>
<!-- / THEME OPTIONS PANEL -->
