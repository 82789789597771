import {Component} from '@angular/core';

@Component({
  selector: 'pristine-demo-sidebar',
  templateUrl: './demo-sidebar.component.html',
  styleUrls: ['./demo-sidebar.component.scss']
})
export class pristineDemoSidebarComponent {
  /**
   * Constructor
   */
  constructor() {
  }
}
